<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        :items-per-page.sync="perPage"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 500],
        }"
        class="elevation-1"
        style="width: 100%"
        :loading="spinnerActive"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Connects</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <v-card style="padding: 20px">
                <v-card-title class="text-h5 mb-4">{{editedItem.domain_data ? editedItem.domain_data.title : "n/a"}} answers</v-card-title>
                  <v-data-table
                    ref="table_inner"
                    :headers="headers_inner"
                    :items="answers"
                    :items-per-page.sync="perPage_inner"
                    :footer-props="{
                      'items-per-page-options': [10, 20, 50, 100, 500],
                    }"
                    class="elevation-1"
                    style="width: 100%"
                    :loading="spinnerActive"
                  ></v-data-table>
                <!-- <p v-for="(answer, i) in answers" :key="i">
                  {{answer.question}}
                  {{answer.answer}}
                  {{answer.email}}
                  {{answer.first_name}}
                  {{answer.last_name}}
                  {{answer.program}}
                  {{answer.survey_name}}
                  {{answer.survey_type}}
                </p> -->
                <!-- <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text>NEXT PAGE</v-btn>
                  <v-btn color="blue darken-1" text>PREV PAGE</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions> -->
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="viewItem(item)">mdi-eye</v-icon>
          <!-- <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon> -->
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:no-data>
          <v-btn color="primary">No data</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { fetchWebsites, fetchConnectAnswers } from "@/services";
import TheSidebar from "@/components/TheSidebar";

export default {
  name: "ProgramSurveyAnswers",
  components: {
    TheSidebar,
  },
  data() {
    return {
      valid: false,
      perPage: 100,
      perPage_inner: 100,
      sortDirection: false,
      sortBy: "domain",
      spinnerActive: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Connect", value: "domain_data.title" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headers_inner: [
        { text: "Program", value: "program" },
        { text: "Survey name", value: "survey_name" },
        { text: "Survey type", value: "survey_type" },
        { text: "Question", value: "question" },
        { text: "Answer", value: "answer" },
        { text: "Email", value: "email" },
        { text: "First name", value: "first_name" },
        { text: "Last name", value: "last_name" },
      ],
      answers: [],
      items: [],
      editedIndex: -1,
      editedItem: { website_config: [{ value: "" }, { value: "" }] },
      defaultItem: { website_config: [{ value: "" }, { value: "" }] },
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    };
  },
  computed: {
    formTitle() {
      return "New Item ??";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  mounted() {
    this.getWebsites();
  },
  methods: {
    async getWebsites() {
      this.spinnerActive = true;
      await fetchWebsites().then(res => {
        if (res.data) {
          this.items = res.data
          this.spinnerActive = false
        }
      }).catch((error) => {
        console.log(error)
        this.spinnerActive = false
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.answers = []
      })
    },
    async viewItem(item) {
      this.editedItem = item
      await fetchConnectAnswers(item.id).then(response => {
        this.answers = response.data.data
        this.dialog = true
        console.log('response', response)
      })
    }
  }
};
</script>
